import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import action from '@store/auth';
import MetaAuth from './MetaAuth';
import CurrencySwitcher from './CurrencySwitcher';
import LanguageSwitcher from './LanguageSwitcher';
import styles from './Meta.module.scss';

const Meta = (props) => {
  const { authorized, actionLogoutUser, user } = props;

  return (
    <>
      <div className={styles.wrapper}>
        <CurrencySwitcher />
        <div className={styles.divider} />
        <LanguageSwitcher />
      </div>
      <MetaAuth
        client={{
          actionLogoutUser,
          authorized,
          avatar: '/img/manager.jpg',
          name: user.email,
        }}
      />
    </>
  );
};

Meta.propTypes = {
  authorized: PropTypes.string,
  actionLogoutUser: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = ({ auth, profile }) => {
  return {
    authorized: auth.token,
    user: profile,
  };
};
const actions = {
  actionLogoutUser: action.logout.trigger,
};

export default connect(mapStateToProps, actions)(Meta);
