import React from 'react';
import styles from './styles.module.scss';

const Button = ({
  children,
  type = 'button',
  theme = 'primary',
  onClick,
  disabled,
  mr,
  ml,
  mb,
  mt,
  center,
  className,
  width,
  color,
  height = '40px',
  borderType = 'solid',
  fontSize = '15px',
  ...props
}) => {
  return (
    <button
      className={[
        styles.btn,
        className,
        disabled && 'disabled',
        theme && theme
      ].join(' ')}
      type={type}
      onClick={onClick}
      color={color}
      style={
        {
          minHeight: height,
          fontSize: fontSize,
          width: width,
          marginTop: mt,
          marginBottom: mb,
          marginLeft: center ? 'auto' : ml,
          marginRight: center ? 'auto' : mr,
        }
      }
      {...props}
    >
      {children}
    </button>
  );
};

export { Button };
