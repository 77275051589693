/* eslint-disable @next/next/no-img-element */
import { LANG } from '../data';
import { useRouter } from 'next/router';
import { setCookie } from 'utils/cookie';
import { useSelector } from 'react-redux';
import { selectSlugList } from 'store/seoSlugList';
import { replaceSlugInUrl } from 'utils/helper/replaceSlugInUrl';
import MetaList from '../MetaList';
import styles from './LanguageSwitcher.module.scss';
import {IW_CDN_URL} from "@src/constants/settings";

const PickedLanguage = ({ icon, code }) => {
  return (
    <>
      <div
        className={styles['pickedIcon']}
        style={{ backgroundImage: `url("${IW_CDN_URL}/img/flags/${icon?.toLowerCase()}.svg")` }}
      />
      <span className={styles['pickedName']}>{code}</span>
    </>
  );
};

const ItemLanguage = ({ item }) => {
  const router = useRouter();
  const { query, asPath, pathname } = router;
  const slugs = {};
  const slugMap = useSelector(selectSlugList);
  const isEmptySlugMap = Object.keys(slugMap).length === 0;
  let routLink = asPath === '/' ? '' : asPath ;

  if (!isEmptySlugMap) {
    for (const key in slugMap) {
      slugs[key] = slugMap[key][item.code] || query[key];
    }
    routLink = replaceSlugInUrl(pathname, slugs);
  }

  return (
    <a className={styles['langLink']} href={`/${item.code}${routLink}`}>
      <img
        className={styles['icon']}
        alt={item?.icon?.toLowerCase()}
        src={`${IW_CDN_URL}/img/flags/${item?.icon?.toLowerCase()}.svg`}
      />
      <span className={styles['itemName']}> {item.name} </span>
    </a>
  );
};

const LanguageSwitcher = () => {
  const router = useRouter();
  const currLanguage = LANG.find((item) => item.code === router.locale) || {};

  const selectedElement = (element) => {
    setCookie('NEXT_LOCALE', element.code);
  };

  return (
    <section className="LanguageSwitcher">
      <MetaList type="language" displayType="icons" picked={currLanguage} list={LANG} selectedElement={selectedElement}>
        <PickedLanguage icon={currLanguage.icon} code={currLanguage.code} />
        <ItemLanguage />
      </MetaList>
    </section>
  );
};

export default LanguageSwitcher;
