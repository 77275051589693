import * as React from 'react';

const BurgerIcon = React.memo(({ className, size, width = 30, height = 19, fill = '#3570C9' }) => {
  const w = size || width;
  const h = size || height;
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="1" fill={fill} />
      <rect y="9" width="30" height="1" fill={fill} />
      <rect y="18" width="30" height="1" fill={fill} />
    </svg>
  );
});

export { BurgerIcon };
