import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { layoutStoreActions } from 'store/layoutStore';
import { Style } from './style';

const ErrorModal = ({ title = '', description = '', textButton }) => {
  const router = useRouter();
  const { t } = useTranslation('error');
  const dispatch = useDispatch();
  const clearMessage = () => dispatch(layoutStoreActions.errorModalState.clear());
  const { errorModal, errorText, subscBtnState, errorTitle } = useSelector((state) => state.layoutStore);

  useEffect(() => {
    return () => {
      errorModal && clearMessage();
    };
  }, []);

  const onSubmit = () => {
    subscBtnState && router.push('/admin/subscription-services/choose-subscription');
    clearMessage();
  };
  const buttonTitle = subscBtnState ? t('error:subscribe') : textButton;
  const textDescription = subscBtnState ? t(errorText) : errorText;
  const textErrorTitle = errorTitle ? t(`error:${errorTitle}`) : '';

  return (
    <Style.Modal open={errorModal} onCancel={onSubmit} centered title={false} footer={null}>
      <Style.InnerContainer>
        <Style.IconErrorMark />
        <Style.Title>{textErrorTitle || title}</Style.Title>
        <Style.Description>{textDescription || description}</Style.Description>
        {/* {description && <Style.Description>{description}</Style.Description>} */}
        <Style.ButtonContainer>
          <Style.Button onClick={onSubmit}>{buttonTitle}</Style.Button>
        </Style.ButtonContainer>
      </Style.InnerContainer>
    </Style.Modal>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  textButton: PropTypes.string.isRequired,
};

export default ErrorModal;
