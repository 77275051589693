import * as React from 'react';

const IconErrorMark = React.memo(({ className, size, height = 40, width = 40 }) => {
  const w = size || width;
  const h = size || height;
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#3570C9" />
      <path
        d="M18.4922 7.8H21.5162L20.9762 25.44H18.9962L18.4922 7.8ZM20.0042 33.18C19.4762 33.18 19.0202 33 18.6362 32.64C18.2762 32.256 18.0962 31.812 18.0962 31.308C18.0962 30.78 18.2762 30.348 18.6362 30.012C18.9962 29.652 19.4522 29.472 20.0042 29.472C20.5322 29.472 20.9762 29.652 21.3362 30.012C21.6962 30.348 21.8762 30.78 21.8762 31.308C21.8762 31.812 21.6962 32.256 21.3362 32.64C20.9762 33 20.5322 33.18 20.0042 33.18Z"
        fill="white"
      />
    </svg>
  );
});
export { IconErrorMark };
