import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { ModalStyle } from './style';

const Modal = (props) => {
  // fix antdesign bug with body overflow - version - "antd": "4.6.6"
  const isDesktopScreen = useMediaQuery({ minWidth: 1150 });
  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
      isDesktopScreen && (document.getElementById('__next').style.overflowY = 'scroll');
    }
    return () => {
      document.body.style.overflow = '';
      isDesktopScreen && (document.getElementById('__next').style.overflowY = '');
    };
  }, [props.open]);

  return <ModalStyle.Modal {...props} />;
};
export default Modal;
