import React, {memo, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { INFO_MAIL, PARTNERS_MAIL } from 'constants/global';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import SocialSprites from 'assets/svg/sprites/social-sprite.svg';
import styles from './Footer.module.scss';
import {ADMIN_URL, IW_CDN_URL} from "@src/constants/settings";
import {getCookie} from "@utils/cookie";

const footerPolicyLink = [
  {
    link: `/privacy-policy`,
    translate_key: 'footer.policy',
  },
  {
    link: `/terms-of-use`,
    translate_key: 'footer.terms',
  },
];
const optionsList = [
  { name: 'EN', url: 'https://iworld.com/en' },
  { name: 'RU', url: 'https://iworld.com/ru' },
];
let linksIcons = [];

const FooterLangMenu = () => {
  return (
    <nav className={styles['nav-lang']}>
      {optionsList.map(({ url, name }, index) => (
        <a key={index} href={url} prefetch={'false'}>
          {name}
        </a>
      ))}
    </nav>
  );
};

const Footer = () => {
  const { t, lang } = useTranslation('common');
  const country = useSelector((state) => state.geoLocation.item) || getCookie('country');
  const footerColumnList = [
    {
      title: 'footer.aboutUs',
      links: [
        {
          link: {
            ru: `/ru/about-us`,
            en: `/en/about-us`
          },
          translate_key: 'footer.about',
          target: "_self"
        },
        {
          link: {
            ru: " https://iworld.com/ru/blog/services/novosti",
            en: "https://iworld.com/en/blog/services/news"
          },
          translate_key: 'footer.news',
          target: "_self"
        },
        {
          link: {
            ru: `/ru/blog`,
            en: `/en/blog`,
          },
          translate_key: 'footer.blog',
          target: "_self"
        },
        {
          link: {
            ru: `https://iworld.com/ru/feedback-ru`,
            en: ``,
          },
          translate_key: 'footer.reviews',
          target: "_self"
        },
        {
          link: {
            ru: `/ru/contacts`,
            en: `/en/contacts`,
          },
          translate_key: 'footer.contacts',
          target: "_self"
        },
        {
          link: {
            ru: '/ru/companies',
            en: '/ru/companies',
          },
          translate_key: 'footer.companies',
          target: "_self"
        },
      ],
    },
    {
      title: 'footer.forClients.title',
      links: [
        {
          link: {
            ru:`${ADMIN_URL}/${lang}/auth/registration-client`,
            en:`${ADMIN_URL}/${lang}/auth/registration-client`
          },
          translate_key: 'footer.forClients.singUp',
          target: "_blank"
        },
        {
          link: {
            ru:`/${lang}/services`,
            en:`/${lang}/services`
          },
          translate_key: 'footer.forClients.listServices',
          target: "_self"
        },
        {
          link: {
            ru:`https://real-estate.iworld.com/${lang}`,
            en:`https://real-estate.iworld.com/${lang}`
          },
          translate_key: 'footer.forClients.realEstate',
          target: "_self"
        },
        {
          link: {
            ru:`/${lang}/list-companies`,
            en:`/${lang}/list-companies`
          },
          translate_key: 'footer.forClients.listCompanies',
          target: "_self"
        },
        {
          link: {
            ru: `https://iworld.com/ru/blog/services/obzory`,
            en: ``,
          },
          translate_key: 'footer.programs',
          target: "_self"
        },
      ],
    },
    {
      title: 'footer.forPartners',
      links: [
        {
          link: {
            ru:`${ADMIN_URL}/${lang}/auth/registration-partner`,
            en:`${ADMIN_URL}/${lang}/auth/registration-partner`
          },
          translate_key: 'footer.registration',
          target: "_blank"
        },
        {
          link: {
            ru:`/${lang}/ecological-strategy-of-company-international-world`,
            en: `/${lang}/ecological-strategy-of-company-international-world`
          },
          translate_key: `footer.ecoStrategy`,
          target: "_self"
        },
      ],
    }
  ];
  useEffect(() => {
    linksIcons = [
      {
        link: 'https://t.me/iworldcom',
        nameIcon: 'telegram',
        hideWithLocation: '',
      },
      {
        link: 'https://www.facebook.com/iworldcom',
        nameIcon: 'facebook',
        hideWithLocation: '',
      },
      {
        link: 'https://twitter.com/iworldcom',
        nameIcon: 'twitter',
        hideWithLocation: '',
      },
      {
        link: 'https://www.instagram.com/iworld.official',
        nameIcon: 'instagram',
        hideWithLocation: '',
      },
      {
        link: 'https://www.linkedin.com/company/iworldcom',
        nameIcon: 'linkedin',
        hideWithLocation: '',
      },
      {
        link: 'https://www.youtube.com/channel/UC49E6mu1gseJ40aj8wDt3Kg',
        nameIcon: 'youtube',
        hideWithLocation: '',
      },
      {
        link: 'https://vk.com/iworldcom',
        nameIcon: 'vk',
        hideWithLocation: 'ua',
      },
      {
        link: 'https://dzen.ru/internationalworld',
        nameIcon: 'traced',
        hideWithLocation: 'ua',
      },
      {
        link: 'https://rutube.ru/channel/23699706',
        nameIcon: 'rutube',
        hideWithLocation: 'ua',
      },
    ]
  },[]);
  return (
    <>
      <SocialSprites className="hidden" />
      <div className={styles['footer']}>
        <div className={styles['info']}>
          <div className="container">
            <div className={styles['content']}>
              <div className={styles.content_top__menu}>
                <div className={styles.content_top__menu_box}>
                  {footerColumnList.map(({ links, title }, ndx) => (
                    <div className={styles['box']} key={ndx}>
                      <p className={styles['title']}>{t(`${title}`)}</p>
                      <div className={styles['linkBox']}>
                        {links.map(({ link, translate_key, target }) => {
                            return link[lang] ? <a href={link[lang]} key={translate_key} data-type={`footer_link__${t(`${translate_key}`)}`} target={target}>
                              {t(`${translate_key}`)}
                            </a> : '';
                          })}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.сommunication}>
                  <div className={styles.сommunication__box}>
                      <a href={`/${lang}/customer-support`} className={styles.сommunication__item}>
                          <img src={`/img/footer/quality.svg`} alt="" />
                          <span>{t('footer.quality')}</span>
                      </a>
                      <a href={`/${lang}/contact-head`} className={styles.сommunication__item}>
                          <img src={`/img/footer/professional.svg`} alt="" />
                          <span>{t('footer.management')}</span>
                      </a>
                  </div>
                </div>
              </div>

              <div className={styles['box-info']}>
                <div className={styles['box-info__mail']}>
                  <p className={styles['title']}>{t('contactUs')}</p>
                  <div className={styles['linksMail']}>
                    <a href={`mailto:${INFO_MAIL}`}>{INFO_MAIL}</a>
                    <a href={`mailto:${PARTNERS_MAIL}`}>{PARTNERS_MAIL}</a>
                  </div>
                </div>

                <div className={styles['box-info__social']}>
                  <p className={styles['title']}>{t('socialNetworks')}</p>
                  <div className={styles['linksSocial']}>
                    {linksIcons.map(({ link, nameIcon, hideWithLocation }, idx) => {
                      return (
                          <div className={styles['singleIcon']} style={{ 'display': hideWithLocation.includes(country) ? 'none' : 'block' }} key={idx}>
                            <Link href={link} prefetch={false}>
                              <a target="_blank" aria-label={nameIcon}>
                                <svg
                                  width="20"
                                  height="20"
                                  alt="icons-social"
                                  style={{ width: 'auto', display: 'block' }}>
                                  <use href={`#svg-${nameIcon}`}></use>
                                </svg>
                              </a>
                            </Link>
                          </div>
                      );
                    })}
                  </div>
                </div>

                <div className={styles['box-info__flag']}>
                  <div className={styles['flag']}>
                  <img src={IW_CDN_URL + "/img/about-us/flag/flag.svg"} alt="Our flag" />
                  </div>
                  <div className={styles['text']}>
                    <p className={styles['description']}>{t('flagText')}</p>
                    <Link href={t('flagBtnLink')} passHref prefetch={false}>
                      <a prefetch={'false'}>{t('flagBtnText')}<span></span></a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['bottomInfo']}>
          <div className="container">
            <hr className={styles['line']} />
            <div className={styles['bottomInfo__box']}>
              <a href={`/${lang}`} className={styles['logo']}>
                <img src={`${IW_CDN_URL}/img/logo/whiteLogo.svg`} alt={t('footer.logoAlt')}  width={'141px'} height={"58px"} loading={'lazy'} />
              </a>
              <div className={styles['partner']}>
                <span>{t('footer.partner')}:</span>
                <img src={`${IW_CDN_URL}/img/footer/MIRSATORI.png`} alt="MIRSATORI"  width={'152px'} height={"32px"} loading={'lazy'} />
              </div>
              <div className={styles['copyright']}>
                <div className={styles['copy_box']}>
                  <span>&copy; iWorld.com { new Date().getUTCFullYear() }. All rights reserved.</span>
                  <FooterLangMenu />
                </div>
                <div className={styles['blockLinks']}>
                  {footerPolicyLink.map(({ link, translate_key }) => {
                    return (
                      <Link href={link} key={translate_key} passHref prefetch={false}>
                        <a className={styles['link-policy']} data-type={`footer_link__${t(`${translate_key}`)}`}>
                          <span>{t(`${translate_key}`)}</span>
                          <span className={styles['arrow']}></span>
                        </a>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Footer);
