import * as React from 'react';

const ArrowLeft = React.memo(({ className, size, width = 6, height = 10, fill = '#3570C9' }) => {
  const w = size || width;
  const h = size || height;
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.15309e-05 5L5.00003 8.43089e-09L5.70703 0.707L1.41403 5L5.70703 9.293L5.00003 10L3.15309e-05 5Z"
        fill={fill}
      />
    </svg>
  );
});

export { ArrowLeft };
