import React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';
import {IW_CDN_URL} from "@src/constants/settings";

const appleIconDate = [
  { rel: 'apple-touch-icon', sizes: '57x57', href: '/img/favicon/apple-icon-57x57.png' },
  { rel: 'apple-touch-icon', sizes: '60x60', href: '/img/favicon/apple-icon-60x60.png' },
  { rel: 'apple-touch-icon', sizes: '72x72', href: '/img/favicon/apple-icon-72x72.png' },
  { rel: 'apple-touch-icon', sizes: '76x76', href: '/img/favicon/apple-icon-76x76.png' },
  { rel: 'apple-touch-icon', sizes: '114x114', href: '/img/favicon/apple-icon-114x114.png' },
  { rel: 'apple-touch-icon', sizes: '120x120', href: '/img/favicon/apple-icon-120x120.png' },
  { rel: 'apple-touch-icon', sizes: '144x144', href: '/img/favicon/apple-icon-144x144.png' },
  { rel: 'apple-touch-icon', sizes: '152x152', href: '/img/favicon/apple-icon-152x152.png' },
  { rel: 'apple-touch-icon', sizes: '180x180', href: '/img/favicon/apple-icon-180x180.png' },
];
const iconDate = [
  { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/img/favicon/android-icon-192x192.png' },
  { rel: 'icon', type: 'image/png', sizes: '120x120', href: '/img/favicon/favicon-120x120.png' },
  { rel: 'icon', type: 'image/png', sizes: '96x96', href: '/img/favicon/favicon-96x96.png' },
  { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/img/favicon/favicon-32x32.png' },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/img/favicon/favicon-16x16.png' },
];

export const NextHeadContent = ({ title, description }) => {
  return (
    <NextHead>
      <title>{title || ''}</title>
      <meta name="description" content={description || ''}/>
      {/* FAVICON for diff devices */}
      {appleIconDate.map((item, index) => (
        <link key={`ap-icon-${index}`} rel={item.rel} sizes={item.sizes} href={item.href}/>
      ))}
      {iconDate.map((item, index) => (
        <link key={`fav-icon-${index}`} rel={item.rel} type={item.type} sizes={item.sizes} href={item.href}/>
      ))}
      <link rel="manifest" href="/img/favicon/manifest.json"/>
      <meta name="msapplication-TileColor" content="#ffffff"/>
      <meta name="msapplication-TileImage" content="/img/favicon/ms-icon-144x144.png"/>
      <meta name="theme-color" content="#ffffff"/>
      {/* <meta httpEquiv="Content-Security-Policy" content="script-src 'none'" />*/}
      <meta property="og:site_name" content="iworld.com"/>
      <meta property="og:type" content="website"/>
      <meta property="og:image" content={`${IW_CDN_URL}/img/logo/iw.png`} />
      <meta property="og:description" content={description || ''}/>
      <meta property="og:title" content={title || ''}/>
    </NextHead>
  );
};
NextHeadContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
