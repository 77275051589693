import styled from 'styled-components';
import Modal from 'antd/lib/modal';

const ModalStyle = {
  Modal: styled(Modal)`
    display: grid;
  `,
};

export { ModalStyle };
