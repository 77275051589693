const CURRENCY = [
  {
    code: 'RUB',
    icon: '₽',
    name: 'RUB',
  },
  // {
  //   flagCode: 'UAH',
  //   icon: '₴',
  //   name: 'Гривна',
  // },
  {
    code: 'USD',
    icon: '$',
    name: 'USD',
  },
  {
    code: 'EUR',
    icon: '€',
    name: 'EUR',
  },
];

export { CURRENCY };

const LANG = [
  {
    icon: 'RU',
    name: 'Русский',
    code: 'ru',
  },
  // {
  //   code: 'UA',
  //   name: 'Украинский',
  //   lng: 'ua',
  // },
  {
    icon: 'GB',
    name: 'English',
    code: 'en',
  },
];

export { LANG };
