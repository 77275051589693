import style from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

export const HeaderBanner = () => {
  const { t } = useTranslation('ads');
  return <div className={`${style.banner} header-banner`}>
      <div className={style.wrap}>
          <div>
            <strong><Trans i18nKey="ads:bannerNewYear.title" components={{ 1: <span /> }}></Trans></strong>
            <span>{t("bannerNewYear.text")}</span>
          </div>
      </div>
    </div>
};
