import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import BurgerMenu from './BurgerMenu';
import styles from './NavMenu.module.scss';
import {IW_CDN_URL} from "@src/constants/settings";
import ItemsList from "@modules/Header/NavMenu/ItemList";

const NavMenu = ({ getAllServices, services, user }) => {
  const { t, lang } = useTranslation('common');
  // const { allServices } = useSelector((state) => state.allServices);
  return (
    <>
      <div className={styles['wrap']}>
        <div className="container">
          <div id="cascaderContainer" className={styles['content']}>
            <a className={styles['iconLogoWrap']} href={`/${lang}`} aria-label="Logo"><img src={`${IW_CDN_URL}/img/logo/logo.svg`} width={'160px'} height={"66px"} alt=""/></a>
            <div className={styles['menu']}>
              <ItemsList></ItemsList>
            </div>

            <div className={styles['burgerMenu']}>
              <BurgerMenu services={services} user={user} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavMenu;
