import * as React from 'react';

const Close = React.memo(({ className, size, width = 20, height = 20 }) => {
  const w = size || width;
  const h = size || height;
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 19L10 10M19 1L10 10M10 10L19 19M10 10L1 1" stroke="#3570C9" />
    </svg>
  );
});

export { Close };
