import React, {memo} from 'react';
import Meta from './Meta';
import NavMenu from './NavMenu';
import styles from './Header.module.scss';

const Header = (props) => {
  return (
    <div className={styles['wrap']}>
      <div className={styles['wrap-meta']}>
        <div className="container">
          <div className={styles['content']}>
            <Meta />
          </div>
        </div>
      </div>
      <NavMenu user={props} />
    </div>
  );
};

export default memo(Header);
