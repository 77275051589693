import { keyframes } from 'styled-components';

const mainBlock = () => {
  return `width: 100%;
  max-width: 1430px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;`;
};
const setWidth = (width) => {
  return `
    width: ${width};
    min-width: ${width};
    max-width: ${width};
    flex: 0 ${width};
  `;
};
const setHeight = (height) => {
  return `
    height: ${height};
    min-height: ${height};
    max-height: ${height};
  `;
};
const setSize = (width, height = false) => {
  return setWidth(width) + setHeight(height || width);
};
const simpleIcon = (width, height = false) => {
  return `display: block; ${setSize(width, height)}`;
};
const translate = (horizontal, vertical = false) => {
  return `transform: translate(${horizontal},${vertical || horizontal})`;
};
const flexCenter = () => {
  return `
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  `;
};
const maxContent = () => {
  return `
  max-width: max-content;
  max-height: max-content;
  `;
};
const scrollBarThis = () => {
  return `
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #bababa;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey;
  }
  `;
};
const transitionCustom = (sek) => {
  return `
  transition: ${sek} ease-out;
  `;
};
const transition = (custom) => {
  return `
  transition: ${custom || '.45s'} ease-out ;
  `;
};
const transitionElastic = (custom) => {
  return `
  transition: ${custom || '1s'} cubic-bezier(0.19, 1, 0.22, 1);
  `;
};
const textEllipsis = () => {
  return `
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  `;
};
const customPlaceholder = (content) => {
  return `
  &::-webkit-input-placeholder {
    ${content}
  }

  &::-moz-placeholder {
    ${content}
  }

  &:-ms-input-placeholder {
    ${content}
  }

  &:-moz-placeholder {
    ${content}
  }
  `;
};
const focusPlaceholder = () => {
  return `${customPlaceholder(
    `
    ${transition()};
    opacity: .7;
    `,
  )}
    &:focus {
      ${customPlaceholder(`
        text-indent: 15px;
        opacity: 0;
      `)}
    }
  `;
};
const appearanceNone = () => {
  return `
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  `;
};
const flex = (justify = 'flex-start', align = 'flex-start', direction = 'row') => {
  return `display: flex;
    justify-content: ${justify};
    align-items: ${align};
    flex-direction: ${direction};`;
};

const respondTo = (size, content) => {
  let media = ``;
  switch (size) {
    case 'hd':
      media = `@media only screen and (max-width: 1920px){${content}}`;
      break;
    case 'hds':
      media = `@media only screen and (max-width: 1600px){${content}}`;
      break;
    case 'lapt':
      media = `@media only screen and (max-width: 1440px){${content}}`;
      break;
    case 'lg':
      media = `@media only screen and (max-width: 1365px){${content}}`;
      break;
    case 'desc':
      media = `@media only screen and (max-width: 1365px){${content}}`;
      break;
    case 'ixmax':
      media = `@media only screen and (max-width: 1365px){${content}}`;
      break;
    case 'ix':
      media = `@media only screen and (max-width: 1023px){${content}}`;
      break;
    case 'md':
      media = `@media only screen and (max-width: 1023px){${content}}`;
      break;
    case 'mds':
      media = `@media only screen and (max-width: 960px){${content}}`;
      break;
    case 'mds-880':
      media = `@media only screen and (max-width: 1023px){${content}}`;
      break;
    case 'mds-805':
      media = `@media only screen and (max-width: 1023px){${content}}`;
      break;
    case 'sm':
      media = `@media only screen and (max-width: 767px){${content}}`;
      break;
    case 'sm-s':
      media = `@media only screen and (max-width: 767px){${content}}`;
      break;
    case 'sms':
      media = `@media only screen and (max-width: 767px){${content}}`;
      break;
    case 'xs':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xsm-m':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xsm':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xsm-s':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xsm-s-420':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xss':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xss-s':
      media = `@media only screen and (max-width: 573px){${content}}`;
      break;
    case 'xss-s-340':
      media = `@media only screen and (max-width: 340px){${content}}`;
      break;
    case '320':
      media = `@media only screen and (max-width: 320px){${content}}`;
      break;
    case 'height-750':
      media = `@media only screen and (max-height: 767px){${content}}`;
      break;
    default:
      media = `@media only screen and (max-width: ${size}){${content}}`;
  }
  return media;
};
const respondFrom = (size, content) => {
  let media = ``;
  switch (size) {
    case 'hd':
      media = `@media only screen and (min-width: 1824px){${content}}`;
      break;
    case 'lapt':
      media = `@media only screen and (min-width: 1440px){${content}}`;
      break;
    case 'lg':
      media = `@media only screen and (min-width: 1366px){${content}}`;
      break;
    case 'desc':
      media = `@media only screen and (min-width: 1224px){${content}}`;
      break;
    case 'md':
      media = `@media only screen and (min-width: 1024px){${content}}`;
      break;
    case 'sm':
      media = `@media only screen and (min-width: 768px){${content}}`;
      break;
    case 'xs':
      media = `@media only screen and (min-width: 574px){${content}}`;
      break;
    case 'xss':
      media = `@media only screen and (min-width: 400px){${content}}`;
      break;
    default:
      media = `@media only screen and (min-width: ${size}){${content}}`;
  }
  return media;
};

const hide = keyframes`from{ transform: rotateX(0deg);
      transform-origin: top;
      opacity: 1;   } to { transform: rotateX(-100deg);
      transform-origin: top;
      opacity: 0;   }`;
const show = keyframes`from{ transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;} to{ transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;   } `;
export {
  setWidth,
  setHeight,
  setSize,
  simpleIcon,
  flexCenter,
  scrollBarThis,
  transitionCustom,
  transition,
  appearanceNone,
  translate,
  customPlaceholder,
  focusPlaceholder,
  flex,
  maxContent,
  mainBlock,
  textEllipsis,
  transitionElastic,
  respondTo,
  respondFrom,
  hide,
  show,
};
