/* eslint-disable @next/next/link-passhref */
import { useState, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'hooks/useOutsideClick';
import styles from './MetaList.module.scss';

const MetaList = ({ title, list, showTop, type, selectedElement, children }) => {
  const [openPopover, openPopoverHandler] = useState(false);
  const wrappedTooltipRef = useRef(null);

  const changeElement = (element) => () => {
    selectedElement(element);
  };

  useOutsideClick(wrappedTooltipRef, () => {
    if (openPopover) openPopoverHandler(false);
  });

  return (
    <div className={`${styles.wrap} ${showTop}`} onClick={() => openPopoverHandler(!openPopover)}>
      <div className={styles.picked__wrap} data-type={`header_${type}`}>
        <span className={styles.title}>{title}</span>
        {children[0]}
        <div className={styles['dropDownArrow']} />
      </div>
      {openPopover && (
        <div className={styles.list} ref={wrappedTooltipRef}>
          <div className={styles.triangle} />
          {list.map((item, index) => {
            return (
              <li
                className={styles['item']}
                data-type={`header_${type}_popover_item`}
                data-content={`header_${type}_popover_item__${item.name}`}
                key={item.name + index}
                onClick={changeElement(item)}>
                {cloneElement(children[1], { item })}
              </li>
            );
          })}
        </div>
      )}
    </div>
  );
};

MetaList.propTypes = {
  title: PropTypes.string,
  showTop: PropTypes.bool,
  type: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default MetaList;
