import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getCookie, removeCookie } from '@utils/cookie';
import { connect, useDispatch } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import action from 'store/profile';
import styles from './MetaAuth.module.scss';
import {ADMIN_URL} from "@src/constants/settings";

const MetaAuth = ({ client, profile, token }) => {
  const { t, lang } = useTranslation('common');
  const dispatch = useDispatch();
  const tokenExists = getCookie('token');

  const [openPopover, openPopoverHandler] = useState(false);
  const wrappedTooltipRef = useRef(null);

  const getProfileData = () => dispatch(action.profile.trigger(token));

  useEffect(() => {
    if (tokenExists) {
      getProfileData(token);
    }
  }, []);

  const NotAuthorized = () => {
    const signUpBtnBlock = [
      {
        data: 'home_header_button__client',
        url: `${ADMIN_URL}/${lang}/auth/registration-client`,
        key: 'header.client',
      },
      {
        data: 'home_header_button__partner',
        url: `${ADMIN_URL}/${lang}/auth/registration-partner`,
        key: 'header.partner',
      },
    ];
    return (
      <div className={styles['metaAuthBlock']}>
        <a href={`${ADMIN_URL}/${lang}/auth/login`} target="_blank">
          <button className={styles['signIn']} data-type={'header_login'}>
            {t('header.login')}
          </button>
        </a>
        <div className={styles['signUpWrapper']}>
          <div className={styles['signUpRegistration']} onClick={() => openPopoverHandler(!openPopover)}>
            <button className={styles['signUpButton']} data-type={'header_signIn'}>
              {t('header.signIn')}
            </button>
            <div className={styles['dropDownArrowNotAuthorized']} open={openPopover} />
          </div>
          {openPopover && (
            <div className={styles['signUpOptions']} ref={wrappedTooltipRef}>
              {signUpBtnBlock.map(({ data, url, key }, ndx) => {
                return (
                  <button className={styles['signUpBtn']} data-type={data}  key={ndx}>
                    <a href={url} target="_blank" >
                      {t(key)}
                    </a>
                  </button>
                );
              })}
              <div className={styles['filledTriangle']} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const Authorized = ({ profile, token }) => {
    const { avatar = '', first_name = '', email = '', role = '', new_messages = '', new_leads = false } = profile;
    return (
      <div className={styles['metaAuthBlock']}>
        <span className={styles['userName']}>{first_name || email}</span>
        <a
          className={styles['userWrap']}
          data-type={'header_openPopoverHandler'}
          href={`${ADMIN_URL}/${lang}/admin/profile`}
          target={"_blank"}
        >
          <div className={styles['imgBox']}>
            <img className={styles['userAvatar']} src={avatar || '/img/user.svg'} width={24} height={24} alt="user" />
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className={styles['wrap']}>
      {client.authorized ? <Authorized token={token} profile={profile} /> : <NotAuthorized />}
    </div>
  );
};

MetaAuth.propTypes = {
  client: PropTypes.shape({
    actionLogoutUser: PropTypes.func.isRequired,
    authorized: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string,
  profile: PropTypes.shape({
    first_name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    avatar: PropTypes.string,
    new_messages: PropTypes.bool,
    new_leads: PropTypes.bool,
  }),
};

const mapStateToProps = ({ auth, profile }) => {
  return {
    token: auth.token,
    profile,
  };
};

export default connect(mapStateToProps)(MetaAuth);
