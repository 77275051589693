const variables = {
  // Global variables
  font: `'Montserrat', sans-serif`,
  fontLight: '300',
  fontRegular: '400',
  fontSemiBold: '500',
  fontBold: '700',
  fontExtraBold: '800',
  green: '#90D285',
  darkGreen: '#43de6f',
  blue: '#3867D6',
  blueHover: '#3c6cff',
  // newsList page
  blueTitle: '#3570C9',
  // Admin/CompanyPage
  blueTitleCompanyPage: '#3570C9',
  // Admin/Profile
  skyblue: '#2B61B4',
  skyblue2: '#69BFFD',
  skyblueHover: '#0387c5',
  red: '#fb4b53',
  darkRed: '#bf3631',
  white: '#FFFFFF',
  whiteLight: '#ffffff',
  grey: '#A5B3C5',
  greyHover: '#92a3b9',
  darkgrey: '#3E5064',
  lightGrey: '#8796A6',
  lightBorder: '#E7ECF1',
  lightHover: '#eff4f9',
  violet: '#404764',
  dark: '#1B1D2A',
  lightDark: '#232B36',
  warning: '#fce095',
  primaryLight: 'rgba(0, 134, 84, 0.08)',
  lightWhite: '#88919A',
  transition: '0.25s',
  lightGrey2: '#E8EAF0',
  mStep: 5,
  pStep: 5,
  mwStep: 50,
  purple: '#F1F4FE',
  lightPurple: '#AEAEAE',
  lightBrown: '#F3F3F3',
  greyBorder: '#e0e4ef',
  lightGreen: '#E3F3E2',
  lightBlue: '#4173CD',
  darkBlue: '#0363D4',
  darkGrey2: '#6A6D6F',
  // ----
  background: '#FFFFFF',
  secondaryBG: '#F5F7FA',
  border: '#DDE4EA',
  disabled: '#DDE4EA',
  accent: '#3570C9',
  accentHover: '#0D4FB2',
  accentActive: '#00317B',
  black: '#2E2E2E',
  azure: '#0051B1',
  field: '#F5F7FA',
  lightGreyText: '#8293AA',
  // ---
  titleText: '#2C3D46',
  titleTextDescr: '#111111',
};
export { variables };
