import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import MenuSprites from "@assets/svg/sprites/menu-sprite.svg";
import Link from 'next/link';

const MenuItem = (lang) => [
  {
    url: '/services',
    icon: 'services',
    name: 'allServices',
    dataType: 'header_nav_services',
  },
  {
    url: `https://real-estate.iworld.com/${lang}`,
    icon: 'real-estate',
    name: 'realEstate',
    dataType: 'header_nav_realEstate',
  },
  {
    url: '/visa-free-map',
    icon: 'visaCard',
    name: 'visaMap',
    dataType: 'header_nav_visaCard',
  },
  {
    url: '/comparison-of-passports',
    icon: 'countries',
    name: 'passport',
    dataType: 'header_nav_countries',
  },
  {
    url: '/partnership',
    icon: 'partnership',
    name: 'partnership',
    dataType: 'header_nav_partnership',
  },
  {
    url: '/about-us',
    icon: 'aboutUs',
    name: 'aboutUs',
    dataType: 'header_nav_aboutUs',
  },
  {
    url: '/contacts',
    icon: 'contacts',
    name: 'contacts',
    dataType: 'header_nav_contacts',
  },
];

const ItemsList = () => {
  const { t, lang } = useTranslation('common');
  const router = useRouter();
  const activeClass = (url) => router.pathname === url ? ' is-active' : '';

  return  <>
    <MenuSprites className="hidden" width={'0'} height={'0'} />
    { MenuItem(lang).map(({url, name, icon}, index) =>  <Link href={url} prefetch={false}  key={`navMenu__${index}`}>
        <a
            className={`${activeClass(url)} header-menu__item`} >
              <svg alt="icons-menu"
                   fill="#3570C9"
                   width="20px"
                   height="20px"
              >
                <use href={`#svg-${icon}`}></use>
              </svg>
              <span>{t(`header.menu.${name}`)}</span>
          </a>
    </Link>
    )
  }
  </>
}

export default ItemsList
