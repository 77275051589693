import styles from "./styles.module.scss";
import {join} from "lodash";
const Loader = (props) => {
  const counter = new Array(12).fill('');
  return <div className={[styles.loaderSpinnerWrap, props.className && props.className].join(' ')}>
      <div className={styles.loaderSpinner}>
        { counter.map((i, index) => <div key={index}></div>) }
      </div>
    </div>
}


export { Loader };
