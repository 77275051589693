/* eslint-disable jsx-a11y/aria-role */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import action from '@store/auth';
import { connect } from 'react-redux';
import { BurgerIcon, Close } from '@icons/Svg/BurgerMenu';
import styles from './BurgerMenu.module.scss';
import dynamic from "next/dynamic";

/* Components */
const MenuList = dynamic(() => import('./MenuList'));
const MetaList = dynamic(() => import('./MetaList'));
const MetaUser = dynamic(() => import('./MetaUser'));
const Drawer = dynamic(() => import('antd/lib/drawer'));

const BurgerMenu = ({ services, authorized, actionLogoutUser, user }) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [showChildDrawer, setShowChildDrawer] = useState(false);

  const showDrawer = () => {
    setVisibleDrawer(true);
    setShowChildDrawer(false);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  return (
    <>
      <div className={styles['burgerBtn']} onClick={showDrawer}>
        <BurgerIcon />
      </div>
      <Drawer
        placement="right"
        destroyOnClose={true}
        keyboard={true}
        closeIcon={<Close />}
        onClose={onCloseDrawer}
        open={visibleDrawer}
        className={styles['drawer']}
        title={
          <div className={styles['titleBox']}>
            <MetaUser
              onCloseDrawer={onCloseDrawer}
              client={{
                actionLogoutUser,
                authorized,
                avatar: '/img/manager.jpg',
                name: user.email,
              }}
            />
          </div>
        }>
        <div id="drawerBody" className={styles['drawerBody']}>
          <div className={showChildDrawer ? styles.mainWrapNone : styles.mainWrap}>
            <MenuList services={services} onCloseDrawer={onCloseDrawer} setShowChildDrawer={setShowChildDrawer} />
            <MetaList setShowChildDrawer={setShowChildDrawer} />
          </div>
        </div>
      </Drawer>
    </>
  );
};

BurgerMenu.propTypes = {
  authorized: PropTypes.string,
  services: PropTypes.array,
  actionLogoutUser: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = ({ auth, profile }) => {
  return {
    authorized: auth.token,
    user: profile,
  };
};
const actions = {
  actionLogoutUser: action.logout.trigger,
};

export default connect(mapStateToProps, actions)(BurgerMenu);
