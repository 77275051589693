import { memo } from 'react';
import { CURRENCY } from '../data';
import { DEFAULT_CURRENCY } from 'constants/global';
import { useRouter } from 'next/router';
import { setCookie, getCookie } from 'utils/cookie';
import MetaList from '../MetaList';
import styles from './CurrencySwitcher.module.scss';

const PickedCurrency = ({ icon, code }) => {
  return (
    <>
      <span className={styles['pickedName']}>{icon}</span>
      <span className={styles['pickedName']}>{code}</span>
    </>
  );
};

const ItemCurrency = ({ item }) => {
  return (
    <div className={styles['currencyItemWrap']}>
      <span className={styles['currency']}>{item.icon}</span>
      <span className={styles['itemName']}>{item.name}</span>
    </div>
  );
};

const CurrencySwitcher = () => {
  const router = useRouter();
  const currency = getCookie('currency') || DEFAULT_CURRENCY;
  const currCurrency = CURRENCY.find((item) => item.code === currency);

  const selectedElement = (element) => {
    setCookie('currency', element.code);
    router.reload();
  };

  return (
    <section className="CurrencySwitcher">
      <MetaList type="currency" list={CURRENCY} selectedElement={selectedElement}>
        <PickedCurrency icon={currCurrency.icon} code={currCurrency.code} />
        <ItemCurrency />
      </MetaList>
    </section>
  );
};

export default memo(CurrencySwitcher);
